import React, { Component, Suspense, useEffect, useState } from 'react'
import { HashRouter, BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import axios from 'axios'
import { useDispatch } from 'react-redux'

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Otp = React.lazy(() => import('./views/pages/register/Otp'))

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const GoogleConsent=React.lazy(()=>import('./views/googleConsent/GoogleConsent'))
const paymentResult = React.lazy(() => import('./views/paymentresult/PaymentResult'))
function App() {
    // const dispatch = useDispatch()
    // constructor(props) {
    // 	super(props);
    // 	this.state = {
    // 	  	language: 'en',
    // 		login:false

    // 	}
    // 	this.handleLanguageChange = this.handleLanguageChange.bind(this);
    // }
    // dispatch({ type: 'set', sidebarShow: "true" })

    const [language, setLanguage] = useState('en')
    const [login, setLogin] = useState(false)

    const handleLanguageChange = (e) => {
        e.preventDefault()
        let lang = e.target.value
        // this.setState(prevState => ({
        //   	language: lang
        // }))
        setLanguage(lang)
    }

    return (
        // <HashRouter>
        <Suspense fallback={loading}>
            <Router>
                <Routes>
                    <Route exact path="/login" name="Login Page" element={<Login />} />
                    <Route exact path="/register" name="Register Page" element={<Register />} />
                    <Route exact path="/register/:id" name="Otp Page" element={<Otp />} />
                    <Route exact path="/404" name="Page 404" element={<Page404 />} />
                    <Route exact path="/500" name="Page 500" element={<Page500 />} />
                    <Route
                        excat
                        path="/auth/consent/:drId"
                        namme="Google Consent"
                        element={<GoogleConsent />}
                    />
                    {/* <Route exact path="/paymentresult?success1" name="paymentresult" element={<paymentResult/>} /> */}
                    <Route path="*" name="Home" element={<DefaultLayout />} />
                </Routes>
            </Router>
        </Suspense>
        //  </HashRouter>
    )
}

export default App
